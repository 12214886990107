<!-- 驾驶舱页面 -->
<template>
  <div class="main-page">
    <div class="top">
      <div class="left" style="display: flex;">
        <div @click="goHome">
          <i class="el-icon-s-home" style="font-size: 17px;margin-right: 8px;"></i>
          <span>进入系统</span>
        </div>
        <div class="yun">
          <router-link :to="'/'">桌面页</router-link>
        </div>
      </div>

      <div class="center">
        <span>丰源水在线智控系统</span>
      </div>
      <div class="right">
        <span style="font-size: 26px; padding-right: 10px">
          {{ weather }}</span><i style="font-size: 22px; padding-right: 10px"> /</i>
        <span style="padding-right: 30px">{{ currentTime }}</span>
        <span class="logout" @click="logout()"> <i class="iconfont icon-tuichu"></i>退出</span>
      </div>
    </div>
    <div class="container">
      <router-view />
      <!-- <router-view v-if="$route.meta.keepAlive"></router-view>
      <router-view v-else></router-view>  -->
    </div>
  </div>
</template>
<script>
  import Menu from '@/components/menu/Menu'
  import axios from 'axios'
  export default {
    components: {
      Menu
    },
    data() {
      // console.log('当前路由', this.$route)
      return {
        currentTime: this.dayjs().format("YYYY/MM/DD HH:mm:ss"),
        timer: null, //定时器
        trendsNav: [], //动态导航
        weather: "",
      }
    },
    mounted() {
      //天气
      axios.get(
          'https://yiketianqi.com/free/day?unescape=1&appid=19897462&appsecret=o23JogiW&lng=121.454094&lat=31.160065')
        .then(
          res => {

            var tem = (!res.data.tem && res.data.tem != 0) ? '--' : res.data.tem;
            var wea = !res.data.wea ? '--' : res.data.wea
            this.weather = tem + '℃' + ' ' + wea

          }
        )

      var UserMenusList = localStorage.getItem('UserMenusList');
      this.chuliData(JSON.parse(UserMenusList)) //处理动态导航
      this.timer = setInterval(() => {
        this.currentTime = this.dayjs().format("YYYY/MM/DD HH:mm:ss");
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
    methods: {
      goHome() {
        this.trendsNav = Array.from(new Set(this.trendsNav)); //数组去重
        console.log(this.trendsNav, 123)
        if (this.trendsNav.length > 0) {
          this.$router.push(this.trendsNav[0])
        }
      },
      chuliData(data) {
        data.forEach(item => {
          if ((item.platformType == 1 || !item.platformType) && item.rightType !=
            3) { //类型是水在线的，因为原前旧的菜单没有编辑所以platformType的值是null,所以这种情况也的考虑到

            if (item.children.length > 0) {
              this.trendsNav.push(item.path)
              this.chuliData(item.children)
            } else {
              this.trendsNav.push(item.path)
            }


          }



        })
      },
      logout() {
        localStorage.clear()
        this.$message.success('您已退出登录')
        this.$router.push('/login')
      },

    }
  }
</script>

<style lang="scss" scoped>
  .yun {
    width: 60px;
    height: 30px;
    background-color: #2570af;
    line-height: 30px;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 15px;

    a {
      font-size: 14px;
      color: #fff;
    }
  }

  .main-page {
    width: 100%;
    height: 100%;
    background: url("@/assets/images/bg.png") no-repeat;
    background-size: 100% 100%;
  }

  .top {
    width: 100%;
    height: 72px;
    background: url('@/assets/images/cockpit/jiashichangTOP.png') 0 0 no-repeat;
    background-size: 100% 100%;
    padding: 0 80px;
    box-sizing: border-box;
    font-weight: bold;
    position: relative;

    .left {
      position: absolute;
      left: 80px;
      font-size: 14px;
      line-height: 56px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
    }

    .center {
      font-size: 28px;
      color: #fff;
      height: 72px;
      line-height: 72px;

      span {
        font-size: 30px;
        color: #fff;
        letter-spacing: 2px;
        font-weight: normal;

      }

      img {
        height: 35px;
        width: auto;
        margin-right: 10px;
      }
    }

    .right {
      position: absolute;
      right: 80px;
      top: 0;
      font-size: 14px;
      line-height: 56px;
      color: #fff;
      font-weight: 500;

      .logout {
        cursor: pointer;

        i {
          margin-right: 10px;
        }
      }
    }
  }

  .container {
    display: flex;
    height: calc(100vh - 72px);
    overflow-y: auto;
  }
</style>
