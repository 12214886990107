import request from '@/utils/Api'
/**
 * ********查询实时数据列表********
 *  address                       string                    地址
    ownerCode                     string                    户号
    linkMan                       string                    联系人
    tel                           string                    电话
    meterNo                       string                    水表号
    meterType                     integer                   表类型                  0.全部 1.LORA 2.NB 3.NB分体
    attackState                   integer                   攻击状态                  全部=-1 ,正常 = 0, 被攻击 = 1, 异常 = 2
    alarmType                     integer                   报警类型                  全部=-1, 低电压 = 1, 通讯故障 = 2, 用水异常 = 3, 阀门异常 = 4,
    type                          integer                   查询类型                  0.全部 1.片区 2.档案
    organId                       integer                   片区/档案ID
  */
export const getRealDataList = (data) => {
  return request({
    url: '/api/realData/realData/getRealDataList',
    method: 'POST',
    data
  })
}

/**
 * ********实时数据导出********
 *  fileName        string        文件名
    url             string      下载地址
  */
export const getRealDataExportInfo = (data) => {

  return request({
    url: '/api/realData/realData/getRealDataExportInfo',
    method: 'POST',
    data,
    timeout: 60*60*1000//1小时
  })
}

/**
 * ********查询历史数据********
 *  type                    integer                                 查询类型              1.片区 2.档案
    organId                 integer                                 片区/档案ID
    startDate               string                                  查询起始日期
    endDate                 string                                  查询截止日期
    ownerCode               string                                  户号
    linkMan                 string                                  联系人
    tel                     string                                  电话
    meterNo                 string                                  水表号
    meterType               integer                                 水表类型              0.全部 1.LORA 2.NB 3.NB分体
    pageSize                integer                                 页大小
    pageIndex               integer                                 页索引
  */
export const getHistoryDataList = (data) => {
  return request({
    url: '/api/realData/historyData/getHistoryDataList',
    method: 'POST',
    data
  })
}

/**
 * ********历史数据导出********
 *  type                    integer                                 查询类型              1.片区 2.档案
    organId                 integer                                 片区/档案ID
    startDate               string                                  查询起始日期
    endDate                 string                                  查询截止日期
    ownerCode               string                                  户号
    linkMan                 string                                  联系人
    tel                     string                                  电话
    meterNo                 string                                  水表号
    meterType               integer                                 水表类型              0.全部 1.LORA 2.NB 3.NB分体
    pageSize                integer                                 页大小
    pageIndex               integer                                 页索引
  */
export const getHistoryDataExportInfo = (data) => {
  return request({
    url: '/api/realData/historyData/getHistoryDataExportInfo',
    method: 'POST',
    data
  })
}
