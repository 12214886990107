<template>
  <div class="allDatePage">
    <div class="mainDatePage">
      <h4>日用水量查询</h4>
      <div class="linContent">
        <p><span>日期</span>
          <el-date-picker size="mini" popper-class='popperclassForMaindatepageLinContent' value-format="yyyy-MM-dd"
            v-model="selectForm.data" type="daterange" range-separator="-" align='center' start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </p>
        <p><span>小区</span>
          <el-select size="mini" v-model="selectForm.villageId" placeholder="请选择"
            @change="getVillageSelectListItemList ('building', 2, selectForm.villageId)">
            <el-option v-for="item in villageList" :key="item.value" :label="item.text" :value="item.value">
            </el-option>
          </el-select>
        </p>
        <p><span>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="doSelect()">搜索</el-button>
          </span>
          <span>
            <el-button type="primary" icon="el-icon-brush" size="mini" @click="resetSelectForm()">清空</el-button>
          </span>
        </p>
        <div class="saiXuanBut" @click="changeDown()">
          <span>高级筛选</span> <i :class="isdown?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
        </div>
      </div>
      <div class="linContent" v-show="isdown">
        <p><span>户号 </span>
          <el-input size="mini" v-model="selectForm.ownerCode" placeholder="户号" @keyup.enter.native="doSelect()">
          </el-input>
        </p>
        <p><span>联系人 </span>
          <el-input size="mini" v-model="selectForm.linkMan" placeholder="联系人" @keyup.enter.native="doSelect()">
          </el-input>
        </p>
        <p><span>电话 </span>
          <el-input size="mini" v-model="selectForm.tel" placeholder="电话" @keyup.enter.native="doSelect()"></el-input>
        </p>
        <!-- <p><span>表号 </span><el-input size="mini"  v-model="selectForm.meterNo" placeholder="表号" @keyup.enter.native ="doSelect()"></el-input></p>
      <p><span>表类型 </span><el-select size="mini" v-model.number="selectForm.meterType" placeholder="请选择">
          <el-option
            v-for="item in allMeterType"
            :key="item.value"
            :label="item.text"
            :value="item.value">
          </el-option>
        </el-select></p> -->
      </div>
      <div class="dataTable">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" stripe border show-overflow-tooltip
          tooltip-effect="dark" style="width: 100%" v-loading="loadingTableData" element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
          <el-table-column prop="address" label="地址" show-overflow-tooltip width="300">
          </el-table-column>
          <el-table-column prop="ownerCode" label="户号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="linkMan" label="联系人" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="tel" label="电话" align="center" show-overflow-tooltip>
          </el-table-column>

          <el-table-column label="用水量" prop="useNum">
          </el-table-column>
          <el-table-column label="统计日" prop="day" width="140" align="center">
          </el-table-column>
          <el-table-column label="统计时间" prop="statAt" width="200">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="thisPage">
      <page :thePage='page'></page>
    </div>
  </div>
</template>

<script>
  import {
    getMeterDayWaterNumList,
    getVillageSelectListItemList
  } from '@/ApI/index'
  import page from '@/components/pubilc/page.vue'
  import mixin from '@/mixins/mixin'
  export default {
    mixins: [mixin],
    components: {
      page
    },
    name: 'DayRecord',
    data() {
      // alert(this.dayjs(this.chooseMonth).startOf('month').format('YYYY-MM-DD'))
      // const month = new Date().getMonth() + 1
      return {
        isdown: false,
        tableHeight: window.innerHeight - 380,
        selectForm: {
          type: 0, // 左侧片区传过来的
          organId: 0, // 左侧片区传过来的
          data: [this.dayjs(this.chooseMonth).startOf('month').format('YYYY-MM-DD'), this.dayjs(this.chooseMonth).endOf(
            'month').format('YYYY-MM-DD')],
          tel: '',
          meterNo: '',
          ownerCode: '',
          linkMan: '',
          meterType: 0,
          villageId: '', // 小区id  new
          buildingId: '', // 楼栋id new
          unitId: '' // 单元id      new
        },
        organId: ''
      }
    },
    mounted() {
      this.$parent.getOrganTree(4) // 11

      this.$bus.$on('leftOrganId', res => {
        this.selectForm.organId = res.organId
        this.selectForm.type = res.type
        this.organId = res.organId
        this.selectForm.villageId = ''
        this.getVillageSelectListItemList('village', this.selectForm.type, res.organId)
        if (res.kind >= 3) {
          this.doSelect()
        } else {
          this.tableData = []
        }
      })
    },
    beforeDestroy() {
      this.$bus.$off('leftOrganId')
    },
    methods: {
      changeDown() {
        this.isdown = !this.isdown
        if (this.isdown) {
          this.tableHeight = window.innerHeight - 420;
        } else {
          this.tableHeight = window.innerHeight - 380;
        }
      },
      // 获取小区、楼栋、单元、采集器等
      async getVillageSelectListItemList(ofVillage, type, organId) {
        if (organId) {
          const {
            data: res
          } = await getVillageSelectListItemList({
            type,
            organId
          })
          this.selectForm.organId = organId
          // this.selectForm.type = type
          if (res.success) {
            if (ofVillage === 'village') {
              this.villageList = res.data
              this.villageList.unshift({
                text: '请选择',
                value: ''
              })
              this.selectForm.type = 1
              this.selectForm.villageId = ''
            } else if (ofVillage === 'building') {
              this.selectForm.type = type
              this.buildingList = res.data
              this.buildingList.unshift({
                text: '请选择',
                value: ''
              })
              this.selectForm.buildingId = ''
              this.selectForm.unitId = ''
            } else if (ofVillage === 'unit') {
              this.selectForm.type = type
              this.unitList = res.data
              this.unitList.unshift({
                text: '请选择',
                value: ''
              })
              this.selectForm.unitId = ''
            }
          } else {
            this.$message.error(res.message)
          }
        } else {
          if (ofVillage === 'building') {
            this.selectForm.organId = this.organId
            this.selectForm.type = 1
            this.selectForm.buildingId = ''
            this.selectForm.unitId = ''
          } else if (ofVillage === 'unit') {
            this.selectForm.organId = this.selectForm.villageId
            this.selectForm.unitId = ''
          }
        }
       if(ofVillage!='village'){
          this.getList()
       }
      },
      async getList() {
        this.loadingTableData = true
        let startDate = ''
        let endDate = ''
        if (this.selectForm.data[0]) {
          startDate = this.selectForm.data[0]
        }
        if (this.selectForm.data[1]) {
          endDate = this.selectForm.data[1]
        }
        const {
          data: res
        } = await getMeterDayWaterNumList({
          type: this.selectForm.type,
          organId: this.selectForm.organId,
          ownerCode: this.selectForm.ownerCode,
          linkMan: this.selectForm.linkMan,
          tel: this.selectForm.tel,
          meterNo: this.selectForm.meterNo,
          meterType: this.selectForm.meterType,
          startDate: startDate,
          endDate: endDate,
          pageIndex: this.page.pageIndex,
          pageSize: this.page.pageSize
        })
        if (res.success) {
          this.tableData = res.data.items
          this.page.dateTotal = res.data.total
          this.loadingTableData = false
        } else {
          this.$message.error(res.message)
        }
      },
      // 执行搜索
      doSelect() {
        this.page.pageIndex = 1
        this.getList()
      },
      // 清空按钮
      resetSelectForm() {
        this.selectForm = {
          type: 0, // 左侧片区传过来的
          organId: this.organId, // 左侧片区传过来的
          data: this.selectForm.data,
          tel: '',
          meterNo: '',
          ownerCode: '',
          linkMan: '',
          meterType: 0,
          villageId: '', // 小区id  new
          buildingId: '', // 楼栋id new
          unitId: '' // 单元id      new
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/css/allDatePage.scss';

  .linContent {
    p {
      display: flex;
      width: 300px;
      padding-left: 18px;

      span {
        display: block;
        text-align: left;
        width: 65px;
        line-height: 30px;

        .el-button {
          padding-left: 6px;
          padding-right: 6px;
        }
      }

      .el-button {
        padding-left: 10px;
      }
    }
  }

  .dataTable {
    .el-table {
      height: 710px;
    }
  }
</style>
